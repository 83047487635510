<template>
  <Mission
    class="no-select"
    :title="title"
    :points="points"
    :showResults="showResults"
  >
    <slot>
      <!-- MissionType -->
    </slot>
  </Mission>
</template>

<script>
import Mission from "./Mission.vue"
// import { GameMixin } from "@/mixins"
export default {
  name: "MissionContainer",
  components: {
    Mission
  },
  computed: {
    showResults() {
      return this.mode === "results"
    },
    title() {
      return this.currentMission.title
    },
    points() {
      return this.currentMission.points
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    }
  },
  props: {
    mode: String
  }
}
</script>
<style scoped>
.no-select {
  user-select: none;
}
</style>
