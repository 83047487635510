<template>
  <v-layout column justify-center fill-height class="mission">
    <transition name="mission-result-transition" mode="out-in">
      <template v-if="showResults">
        <PollResultContainer v-if="behavior === 'Poll'" />
        <ResultContainer v-else />
      </template>
      <v-layout v-else column justify-center fill-height>
        <!-- Mission -->
        <slot>
          <!-- Mission component -->
        </slot>
        <!-- Score -->
        <div class="mission-score" v-if="points > 0">
          <div class="mission-score-bg">
            <div class="mission-score-number">
              {{ points }}
            </div>
            <div class="mission-pts-text">PTS</div>
          </div>
        </div>
        <!-- Mission type -->
        <div class="mission-type">
          <div class="mission-type-bg">
            <div class="mission-type-text">
              {{ title }}
            </div>
          </div>
        </div>
      </v-layout>
    </transition>
  </v-layout>
</template>

<script>
import ResultContainer from "./ResultContainer"
import PollResultContainer from "./PollResultContainer"
export default {
  name: "Mission",
  components: {
    ResultContainer,
    PollResultContainer
  },
  computed: {
    behavior() {
      return this.$store.getters.getCurrentMission.behavior
    }
  },
  props: {
    showResults: Boolean,
    title: String,
    points: [String, Number]
  }
}
</script>

<style lang="scss" scoped>
/* mission-result animation */
.mission-result-transition-enter-active,
.mission-result-transition-leave-active {
  transition: all ease 0.3s;
  opacity: 1;
}

.mission-result-transition-enter-active {
  transition-delay: 0.3s;
}

.mission-result-transition-enter,
.mission-result-transition-leave-to {
  opacity: 0;
}

.mission {
  @extend .rtb-border, .rtb-border-radius;
  position: relative;
  overflow: hidden;
  max-height: 300px;
  background-color: $color-white;
  padding: 5px;
  box-shadow: 0 0 3px rgba($color-black, 0.5);
}

.mission.hide-out {
  animation-name: hide-out;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-iteration-count: forwards;
  animation-fill-mode: both;
}
@keyframes hide-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    transform: scale(0.6);
    opacity: 0;
  }
}

.mission.bring-back {
  animation-name: bring-back;
  animation-duration: 1s;
  animation-iteration-count: forwards;
  animation-fill-mode: both;
}
@keyframes bring-back {
  0% {
    transform: scale(0.6);
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.mission-type {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -8px;
  margin-top: -8px;
  color: $color-white;
  font-size: 1.2rem;
}

.mission-type-bg {
  @extend .rtb-border-radius;
  background-color: $color-primary-dark;
  padding-top: 14px;
  padding-bottom: 6px;
  padding-right: 20px;
  padding-left: 14px;
}

.mission-type-text {
  font-size: 13px;
  line-height: 14px;
  height: 14px;
  font-style: italic;
}

.mission-pts-text {
  font-size: 12px;
  line-height: 14px;
  height: 14px;
}

.mission-score {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -8px;
  margin-bottom: -8px;
  color: $secondary_accent_color;
  font-size: 1.2rem;
}

.mission-score-number {
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  height: 20px;
}

.mission-score-bg {
  @extend .rtb-border-radius;
  color: $color-white;
  background-color: $color-primary-dark;
  padding-bottom: 14px;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 12px;
}

// implement scaling with relative font size instead
// @media (max-width: 768px) {
//   .mission-score-number {
//     height: auto;
//     font-size: 16px;
//     line-height: 16px;
//   }
//   .mission-pts-text {
//     font-size: 10px;
//     line-height: 12px;
//   }
//   .mission-score-bg {
//     padding-bottom: 12px;
//     padding-top: 6px;
//     padding-left: 18px;
//     padding-right: 12px;
//   }
// }
</style>
