var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "mission",
      attrs: { column: "", "justify-center": "", "fill-height": "" }
    },
    [
      _c(
        "transition",
        { attrs: { name: "mission-result-transition", mode: "out-in" } },
        [
          _vm.showResults
            ? [
                _vm.behavior === "Poll"
                  ? _c("PollResultContainer")
                  : _c("ResultContainer")
              ]
            : _c(
                "v-layout",
                {
                  attrs: { column: "", "justify-center": "", "fill-height": "" }
                },
                [
                  _vm._t("default"),
                  _vm.points > 0
                    ? _c("div", { staticClass: "mission-score" }, [
                        _c("div", { staticClass: "mission-score-bg" }, [
                          _c("div", { staticClass: "mission-score-number" }, [
                            _vm._v(" " + _vm._s(_vm.points) + " ")
                          ]),
                          _c("div", { staticClass: "mission-pts-text" }, [
                            _vm._v("PTS")
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "mission-type" }, [
                    _c("div", { staticClass: "mission-type-bg" }, [
                      _c("div", { staticClass: "mission-type-text" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " ")
                      ])
                    ])
                  ])
                ],
                2
              )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }