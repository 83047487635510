<template>
  <v-flex
    pa-2
    ref="root"
    class="poll-result-wrap"
    :class="{ overflow: !playing }"
  >
    <v-layout
      column
      justify-center
      style="min-height: 100%; max-height: fit-content"
    >
      <div v-if="false" class="poll-instructions"></div>
      <v-flex
        shrink
        v-for="(obj, index) in dataMutable"
        :key="'poll-result-' + index"
        class="poll-result"
        :class="getRowClass(index)"
      >
        <v-layout row style="position: relative">
          <div
            class="percent-indicator"
            :style="{ width: obj.percent + '%' }"
          ></div>
          <v-flex class="left-col">
            <v-layout row style="position: relative">
              <!-- Team rank -->
              <v-flex shrink d-flex align-center justify-center>
                <div class="poll-rank">
                  {{ index + 1 }}
                </div>
              </v-flex>
              <v-flex shrink d-flex text-xs-left align-center class="team-name">
                {{ obj.option }}
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- Team total-->
          <v-flex shrink class="v-align team-total">
            <div>{{ obj.percent }}<span class="total-percent">%</span></div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-btn v-if="false" class="breakout">CREATE BREAKOUT</v-btn>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import { Power3, TweenMax } from "gsap/TweenMax"
export default {
  name: "PollResult",
  props: {
    data: Array
  },
  data() {
    return {
      dataMutable: [],
      playing: true,
      instructions: ""
    }
  },
  computed: {
    ...mapGetters("GameUsers", ["usersArray"]),
    isHost() {
      return this.$store.getters.user.role == "facilitator"
    }
  },
  methods: {
    changeTeams(option) {
      const message =
        "Are you sure you want to switch up teams. This is irreversible?"
      const asnwer = confirm(message)
      if (asnwer) {
        var trueOnline = this.usersArray.filter(item => {
          return item.teamID !== 0 && item.role === "player"
        })
        let n
        for (var i = 0; i < trueOnline.length; i++) {
          if (option == "next") n = this.nextTeamID(trueOnline[i].teamID)
          if (option == "backForth")
            n = this.backForthTeamID(trueOnline[i].teamID, i)
          if (option == "random") n = this.randomTeamID(trueOnline[i].teamID, i)
          if (option == "spread") n = this.randomTeamID(trueOnline[i].teamID, i)
          if (option == "bounce") {
            this.$store.dispatch("updateUserToTeamID", {
              teamID: 0,
              userID: trueOnline[i].id
            })
          } else {
            this.$store.dispatch("updateUserToTeamID", {
              teamID: n[0],
              userID: trueOnline[i].id
            })
          }
        }
      }
    },
    getRowClass(idx) {
      if (idx === 0) {
        return "poll-result-top"
      } else {
        return "poll-result-sub"
      }
    },
    isTop(idx) {
      return this.dataMutable[idx].top
    }
  },
  mounted() {
    this.dataMutable = this.data.map(obj => ({
      ...obj,
      top: false
    }))

    this.dataMutable[0].top = true

    if (this.dataMutable.length > 1) {
      for (let i = 1; i < this.dataMutable.length; i++) {
        if (this.dataMutable[i].percent === this.dataMutable[i - 1].percent) {
          this.dataMutable[i].top = true
        } else {
          break
        }
      }
    }

    // $nextTick because the component is wrapped
    // into <transition>
    this.$nextTick(() => {
      const collection = this.$refs.root.getElementsByClassName("poll-result")
      TweenMax.staggerFrom(
        collection,
        0.5,
        {
          y: 50,
          opacity: 0,
          delay: 0.3
        },
        0.2,
        () => {
          this.playing = false
        }
      )

      const array = this.dataMutable.filter(obj => obj.percent > 0)
      for (let i = 0; i < array.length; i++) {
        const obj = array[i]
        TweenMax.from(obj, 1, {
          percent: 0,
          decimal: 0,
          ease: Power3.easeOut,
          roundProps: "percent",
          delay: 0.5 + i * 0.2
        })
      }
    })
  }
}
</script>

<style lang="scss">
.poll-result-wrap {
  overflow: hidden;
  &.overflow {
    overflow: auto;
  }
  .percent-indicator {
    top: 0;
    left: 0;
    position: absolute;
    height: 38px;
    width: 0%;
    transition: background-color 0.5s !important;
    background-color: $color-grey-light5;
  }
  .v-align {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .poll-result {
    font-size: 1.2em;
    font-weight: bold;
    background-color: $color-grey-light2;
    height: 38px;
  }
  .poll-instructions {
    font-size: 20px;
    color: $color-primary-dark;
    height: 36px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  .left-col {
    padding: 6px 12px;
    position: relative;
  }
  .poll-rank {
    width: 1.6em;
    height: 1.6em;
    line-height: 1.7em;
    border-radius: 3px;
    background-color: $color-grey2;
    color: $color-white;
    font-weight: bold;
    margin-right: 12px;
    font-size: 17px;
    transition: background-color 0.5s !important;
  }
  .team-name {
    position: relative;
    font-size: 17px;
    line-height: 1em;
  }
  .team-total {
    position: relative;
    text-align: center;
    min-width: 3.5em;
    font-size: 24px;
  }
  .total-percent {
    font-size: 24px;

    position: relative;
    margin-left: 1px;
  }
  .poll-result.poll-result-top .percent-indicator {
    background-color: $color-green-light2;
  }
  .poll-result.poll-result-sub .percent-indicator {
    background-color: $color-yellow-dark;
  }
  .poll-result.poll-result-top .poll-rank {
    background-color: $color-grey2;
  }
  .poll-result:not(:last-child) {
    margin-bottom: 10px;
  }
  .breakout {
    color: $color-white;
    background-color: $primary_accent_color !important;
    border-radius: 8px;

    margin-top: -25px;
    margin-bottom: 10px;
  }
}
</style>
