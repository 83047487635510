<template>
  <PollResult :data="data" />
</template>

<script>
import PollResult from "./PollResult.vue"
// The component connects the child component to the store
export default {
  name: "PollResultContainer",
  components: {
    PollResult
  },
  methods: {
    dedupe(array) {
      return array.filter(({ show }) => show)
    }
  },
  computed: {
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    // probably not the best way to gather the answers
    answers() {
      const MAX_NUMBER_OF_ANSWERS = 30
      const array = []
      for (let i = 1; i < MAX_NUMBER_OF_ANSWERS; i++) {
        const answer = this.currentMission[`answer${i}`]
        if (answer) array.push(answer)
        else break
      }
      return array
    },
    // contans the number of submissions
    options() {
      return this.answers.map(string => {
        const obj = this.missionPlaysArrayGroupedByAnswer[string]
        return {
          option: string,
          num: obj ? obj.length : 0
        }
      })
    },
    // the total number of submissions
    total() {
      return this.options.reduce((acc, val) => {
        return acc + val.num
      }, 0)
    },
    missionPlaysArray() {
      return this.$store.getters.missionPlaysArray
    },
    missionPlaysArrayGroupedByAnswer() {
      // turn an array into a string
      const array = this.missionPlaysArray.map(obj => ({
        ...obj,
        answer: obj.answer[0]
      }))
      // group
      return array.reduce((acc, val) => {
        if (val.answer in acc) {
          acc[val.answer].push(val)
        } else {
          acc[val.answer] = [val]
        }
        return acc
      }, {})
    },
    // make sure the plays belogn to the current mission
    data() {
      const array = this.options.map(obj => {
        let num = 0
        if (this.total) num = (obj.num / this.total) * 100
        // round to one decimal
        const percent = Math.round(num * 10) / 10
        const instructions = this.currentMission.instructions
        return {
          ...obj,
          percent,
          instructions
        }
      })
      array.sort((a, b) => b.percent - a.percent)
      return array
    }
  }
}
</script>
